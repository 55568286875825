<template>
	<div id="main">
		<section id="heroContent">
			<div id="heroText">
				<h1 class="main2">Uh Oh!</h1>
				<h1 class="main1">You must be lost.</h1>
				<h3 class="heroHeadline">
					"bitfluent.xyz{{ pathName }}" is not a valid path.
				</h3>
			</div>
			<div id="heroImgCont">
				<img src="@/assets/404.svg" id="heroImg" />
			</div>
		</section>
	</div>
</template>

<script>
// Dependency Imports
import { ref } from 'vue';
import { useRoute } from 'vue-router';

// Component Imports

export default {
	name: 'Home',
	components: {},

	setup() {
		const route = useRoute();
		let pathName = ref(route.path);

		return {
			pathName,
		};
	},
};
</script>

<style scoped>
a {
	text-decoration: none;
	color: rgba(255, 255, 255, 1);
}

#main {
	border: solid yellow 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	/* z-index: 10; */
}
#heroContent {
	height: auto;
	width: 100%;
	margin: 0px;
	position: relative;
	z-index: 0;
	border: solid green 0px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 0;
}
#heroText {
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: flex-start;
	border: solid green 0px;
	padding: 0px 0px 0px 40px;
	margin: 30px 0px 30px 0px;
	border: solid blue 0px;
	max-width: 510px;
	width: 100%;
}

#cards {
	margin: 60px 0px 0px 0px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

#bulletPoints {
	border: solid blue 0px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0px 0px 0px 0px;
}

#findOutMore {
	width: 100%;
	height: auto;
	min-height: 150px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 100px 0px 100px 0px;
}

.continue {
	width: 280px;
	height: 60px;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border-radius: 60px;
	margin: 20px;
	color: rgba(255, 255, 255, 0.9);
	background: linear-gradient(
		145deg,
		rgba(0, 172, 255, 1) 0%,
		rgba(179, 0, 255, 1) 100%
	);
	background-size: 200%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	transition: cubic-bezier(0.56, 0, 0.39, 2.5) 230ms;
}

.continue:hover {
	transform: translateY(-3px);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}

.continue:active {
	transform: translateY(2px);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.moreButton {
	background-position: 0px 0px;
}

.portButton {
	background-position: 300px;
}

h1 {
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 26px;
	margin: -10px 0px -10px 0px;
	border: solid yellow 0px;
}

h1.main1 {
	font-size: clamp(10px, 11vw, 50px);
	text-align: left;
}

h1.main2 {
	font-size: clamp(20px, 16vw, 90px);
}

h3.heroHeadline {
	font-size: clamp(8px, 6vw, 24px);
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 18px;
	border: solid yellow 0px;
	width: 90%;
	max-width: 500px;
	text-align: left;
	font-weight: 500;
	margin: 10px 0px 0px 0px;
}

#heroImg {
	width: 100vw;
	max-width: 650px;
	height: auto;
	margin: -50px -50px -50px -50px;
	border: solid blue 0px;
	animation: heroImageBounce 10000ms ease-in-out infinite;
}

#heroImgCont {
	border: solid yellow 0px;
}

/* Animations */

@keyframes heroImageBounce {
	0% {
		transform: translateY(-4px) rotate(0.5deg);
	}
	50% {
		transform: translateY(4px) rotate(-0.5deg);
	}
	100% {
		transform: translateY(-4px) rotate(0.5deg);
	}
}

@media only screen and (max-width: 800px) {
}
</style>
